body {
	display: flex;
	flex-direction: column;
	height: 100%;
	font-size: 14px;
	line-height: 21px;
	font-family: 'Montserrat', Helvetica, Arial, sans-serif;
	color: $default;
	background-color: $light;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	overflow-x: hidden;
}

p {
	font-size: 14px;
	line-height: 21px;
}

img {
	display: block;
	width: 100%;
	font-size: 12px;
	line-height: 14px;
}

.row.min {
	margin-left: -5px;
	margin-right: -5px;
}

.row.min > div[class^="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}

.overflow {
	overflow: hidden !important;
}

.hidden {
	display: none !important;
}

.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

.clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.icon {
	width: 20px;
	height: 20px;
	fill: currentColor;
}

a,
button,
input,
select,
textarea,
span,
.icon {
	transition: all 150ms ease;
}

section, .section {
	position: relative;
	padding: 40px 0;
}

.section {
	&__title {
		font-size: 36px;
		line-height: 40px;
		font-weight: 300;
		margin-bottom: 40px;
		text-align: center;
	}
	&__white {
		background-color: $white;
	}
}

.page {
	&__title {
		display: block;
		font-size: 26px;
		line-height: 32px;
		font-weight: 300;
		color: $default;
		margin-bottom: 30px;
	}
	&__content {
		padding-bottom: 50px;
	}
}

/* link */
.link {
	color: $primary;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

/* btn */
.btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	line-height: 1.1;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	text-align: center;
	height: 45px;
	padding: 0 40px;
	position: relative;
	&__primary {
		color: $white;
		background-color: $default;
		&:hover {
			background-color: darken($default, 10%);
		}
	}
	&__secondary {
		color: $white;
		background-color: $secondary;
		&:hover {
			background-color: darken($secondary, 10%);
		}
	}
	&__solid {
		color: $default;
		background-color: transparent;
		border: 1px solid $default;
		&:hover {
			color: $white;
			background-color: $default;
		}
	}
    &__disable {
        color: $gray;
        background-color: $gray-100;
        &:hover {
            color: $white;
            background-color: $gray;
        }
    }
	&__min {
		height: 45px;
		font-size: 13px;
		min-width: 170px;
	}
	.icon {
		display: block;
		width: 12px;
		height: 12px;
		fill: $white;
		position: absolute;
		top: 50%;
		right: 20px;
		margin-top: -6px;
	}
	&__counter {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 35px;
		min-width: 35px;
		height: 35px;
		color: $default;
		border-radius: 50%;
		background-color: $light;
		&:hover {
			color: $white;
			background-color: $secondary;
		}
		.icon {
			width: 14px;
			height: 14px;
		}
	}
	&__action {
		color: $white;
		span {
			display: block;
			position: relative;
			z-index: 2;
		}
		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: all 150ms ease;
			transform: perspective(200px);
		}
		&:before {
			border: 1px solid $default;
		}
		&:after {
			background-color: $default;
		}
		&:hover {
			&:before {
				transform: perspective(200px) rotateX(0) rotateY(20deg);
			}
			&:after {
				transform: perspective(200px) rotateX(0) rotateY(-20deg);
			}
		}
	}
}

/* label */
.label {
	display: block;
	width: 100%;
	position: relative;
	margin-bottom: 20px;
	&__radio {
		display: block;
		font-size: 14px;
		line-height: 19px;
		color: $default;
		padding-left: 27px;
		padding-right: 5px;
		position: relative;
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
		}
		&:before {
			top: 0;
			left: 1px;
			width: 16px;
			height: 16px;
			border: 1px solid $default-200;
			border-radius: 50%;
			background-color: $white;
		}
		&:after {
			top: 3px;
			left: 4px;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: $gray;
			opacity: 0;
			transition: opacity 100ms ease;
		}
	}
	&__checkbox {
		display: block;
		font-size: 14px;
		line-height: 19px;
		color: $default;
		padding-left: 27px;
		padding-right: 5px;
		position: relative;
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
		}
		&:before {
			top: 0;
			left: 0;
			width: 16px;
			height: 16px;
			border: 1px solid $default-200;
			background-color: $white;
		}
		&:after {
			top: 3px;
			left: 3px;
			width: 10px;
			height: 10px;
			opacity: 0;
			background-color: $gray;
			transition: opacity 100ms ease;
		}
	}
	&__bottom {
		text-align: center;
		padding-top: 20px;
	}
}

/* input */
.input {
	display: inline-block;
	font-size: 14px;
	color: $default;
	padding: 0 5px;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid $default;
	background-color: transparent;
	text-overflow: ellipsis;
	&::-webkit-input-placeholder {
		color: $gray;
		opacity: 1;
	}
	&::-moz-placeholder {
		color: $gray;
		opacity: 1;
	}
	&:-moz-placeholder {
		color: $gray;
		opacity: 1;
	}
	&::-ms-input-placeholder {
		color: $gray;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $gray;
		opacity: 1;
	}
	&:focus,
	&:active {
		border-color: $primary;
	}
	&[disabled] {
		background-color: $light;
		opacity: .5;
	}
	// invalid
	&:invalid {
		box-shadow: none;
	}
	&:-moz-submit-invalid {
		box-shadow: none;
	}
	&:-moz-ui-invalid {
		box-shadow:none;
	}

	&__min {
		font-size: 13px;
		height: 30px;
	}

	// checkbox
	&__radio,
	&__checkbox {
		display: none;
		position: absolute;
		width: 1px;
		height: 1px;
		border: 0;
		padding: 0;
		clip: rect(0 0 0 0);
		overflow: hidden;
		&:checked {
			+ .label__radio:before {
				border-color: $default-200;
			}
			+ .label__radio:after {
				opacity: 1;
			}
			+ .label__checkbox:before {
				border-color: $default-200;
			}
			+ .label__checkbox:after {
				opacity: 1;
			}
		}
		&:disabled {
			+ .label__radio {
				color: $gray;
			}
			+ .label__checkbox {
				color: $gray;
			}
			+ .label__radio:before {
				background-color: $default-200;
				border-color: $default-200;
			}
			+ .label__checkbox:before {
				background-color: $default-200;
				border-color: $default-200;
			}
		}
	}
	// textarea
	&__textarea {
		display: block;
		width: 100%;
		outline: none;
		resize: vertical;
		line-height: 1.3;
		height: 90px;
		min-height: 90px;
		max-height: 180px;
		border-top: none;
		border-left: none;
		border-right: none;
	}
	&__error {
		border-bottom-color: $danger;
	}

	&__counter {
		border-bottom: 1px solid $default;
		text-align: center;
		padding: 0 10px;
		margin: 0 10px;
	}
}

/* select */
.select {
	display: inline-block;
	font-size: 14px;
	color: $default;
	width: 100%;
	height: 40px;
	padding: 0 5px;
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid $default;
	background-color: transparent;
	text-overflow: ellipsis;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	background-size: 7px;
	background-position: center right 3px;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 4'%3E%3Cpath d='M2.5 3.9L.3.1h4.4z'/%3E%3C/svg%3E");

	&[disabled] {
		background-color: $default-200;
		opacity: .5;
		border: none;
		padding: 0 10px;
	}
	// remove arrow IE
	&::-ms-expand {
		display: none;
	}
	&__min {
		font-size: 13px;
		height: 30px;
	}
}

/* scroll up */
.scrollup {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	position: fixed;
	right: 17px;
	bottom: 70px;
	border-radius: 50px;
	background-color: $white;
	border: 1px solid $secondary;
	color: $secondary;
	opacity: 0;
	visibility: hidden;
	z-index: 5;
	&:hover {
		color: $white;
		background-color: $secondary;
	}
	.icon {
		width: 12px;
		height: 12px;
	}
	&.is-show {
		opacity: 1;
		visibility: visible;
	}
}

/* call up */
.callup {
    display: flex;
    justify-content: center;
    align-items: center;
	position: fixed;
	width: 30px;
	height: 30px;
	bottom: 70px;
	left: 10px;
	z-index: 5;
	.icon {
		width: 30px;
		height: 30px;
	}
}

/* viber up */
.viberup {
    display: flex;
    justify-content: center;
    align-items: center;
	position: fixed;
	width: 30px;
	height: 30px;
	bottom: 20px;
	left: 10px;
	z-index: 5;
	.icon {
		width: 30px;
		height: 30px;
	}
}

/* tabs */
.tabs {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 14px;
		line-height: 1.1;
		font-weight: 500;
		color: $default;
		height: 40px;
		background-color: transparent;
		margin-bottom: 20px;
		position: relative;
		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 50%;
			bottom: -10px;
			height: 1px;
			width: 100px;
			background-color: $gray-100;
			margin-left: -50px;
		}
		&:last-of-type {
			margin-bottom: 0;
			&:before {
				display: none;
			}
		}
		&:hover {
			color: $secondary;
		}
		&.is-active {
			color: $secondary;
		}
	}
	&__item {
		display: none;
		&.is-show {
			display: block;
		}
	}
}

/* slider */
.slider {
	&__container {
		position: relative;
        overflow: hidden;
		.swiper-container-horizontal {
			position: relative;
		}
		.product__item {
			margin-bottom: 30px;
		}
	}
	&__tabs {
		overflow: hidden;
		.tabs__item {
			opacity: 0;
			height: 0;
			visibility: hidden;
			position: relative;
			display: block;
			&.is-show {
				opacity: 1;
				height: 100%;
				visibility: visible;
			}
		}
	}
}

/* breadcrumbs */
@import "../components/breadcrumbs";

/* rating */
@import "../components/rating";

/* price range */
@import "../components/price-range";
