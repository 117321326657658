html {
	font-family: sans-serif;
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body {
	width: 100%;
	height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeSpeed;
}

html,
body,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
figure {
	margin: 0;
	padding: 0;
}

ul li,
ol li {
	list-style: none;
}

a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
}

a:focus {
	outline: 0;
}

img,
button,
input,
button,
iframe {
	border: 0;
}

button {
	padding: 0;
	cursor: pointer;
	background-color: transparent;
}

a,
input,
select,
button,
textarea {
	outline: none;
	font-family: inherit;
}

input[type="radio"] {
	margin: 0;
}

// shadow IOS
input, textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

video {
	display: block;
	width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}
