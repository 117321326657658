// genearl
$white: #ffffff;
$black: #000000;

// main
$primary: #a37e2c;
$secondary: #127749;
$default: #333333;
$gray: #808080;
$light: #f2f2f2;

// info
$danger: #d02e32;
$success: #6ec342;
$info: #00a6af;
$warning: #fbf8db;

// shades
$gray-100: #e5e5e5;
$gray-200: #b2b2b2;
$default-100: #4c4c4c;
$default-200: #cccccc;

$viber:#7439aa;


// mixins
@mixin linear-gradient($direction, $fromColor, $toColor) {
  background-color: $fromColor;
  background-image: linear-gradient($direction, $fromColor, $toColor);
}

