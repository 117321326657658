.rating {
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 0 1px;
    color: $primary;
    .icon {
      width: 20px;
      height: 20px;
    }
    &.disabled {
      color: $default-200;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    padding-top: 5px;
    margin-bottom: 15px;
  }
  &__title {
    font-size: 13px;
    line-height: 22px;
    padding: 0;
    margin-right: 10px;
    color: #212121;
  }
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }
  &__label {
    display: block;
    width: 20px;
    height: 20px;
    float: right;
    padding-right: 3px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23cccccc'%3E%3Cpath d='M19.5 7.9l-5.7 4.3 2 6.8-5.8-4-5.9 4 2-6.8L.5 7.9l7.1-.2L10 1l2.4 6.7 7.1.2z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left center;
    position: relative;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.rating > .rating__input:checked ~ .rating__label,
.rating:not(:checked) > .rating__label:hover,
.rating:not(:checked) > .rating__label:hover ~ .rating__label {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23a37e2c'%3E%3Cpath d='M19.5 7.9l-5.7 4.3 2 6.8-5.8-4-5.9 4 2-6.8L.5 7.9l7.1-.2L10 1l2.4 6.7 7.1.2z'/%3E%3C/svg%3E");
}

.rating > .rating__input:checked + .rating__label:hover,
.rating > .rating__input:checked ~ .rating__label:hover,
.rating > .rating__label:hover ~ .rating__input:checked ~ .rating__label,
.rating > .rating__input:checked ~ .rating__label:hover ~ .rating__label  {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23a37e2c'%3E%3Cpath d='M19.5 7.9l-5.7 4.3 2 6.8-5.8-4-5.9 4 2-6.8L.5 7.9l7.1-.2L10 1l2.4 6.7 7.1.2z'/%3E%3C/svg%3E");
}

