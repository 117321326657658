.wysiwyg {
  h1,h2,h3,h4,h5,h6 {
    padding-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    line-height: 26px;
    color: $default;
    margin-bottom: 12px;
  }
  img {
    display: inline;
    width: auto;
    max-width: 100%;
  }
  a {
    color: $primary;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  ol {
    counter-reset: section;
    padding-top: 5px;
    margin-bottom: 10px;
    li {
      font-size: 14px;
      line-height: 26px;
      color: $default;
      padding-left: 20px;
      margin-bottom: 5px;
      position: relative;
      &:before {
        counter-increment: section;
        content: counter(section) ".";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        color: $primary;
      }
    }
  }
  ul {
    padding-top: 5px;
    margin-bottom: 10px;
    li {
      font-size: 14px;
      line-height: 26px;
      color: $default;
      padding-left: 20px;
      margin-bottom: 5px;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background-color: $primary;
        border-radius: 50%;
      }
    }
  }
    table {
        width: 100%;
        tr {
            td {
                padding: 10px 15px;
            }
            &:nth-child(odd) {
                background-color: $white;
            }
            &:nth-child(even) {
                background-color: $light;
            }
        }
    }
}
