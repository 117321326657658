// 426
@media screen and (min-width: 426px) {
  .btn {
    font-size: 14px;
    height: 50px;
    &__min {
      font-size: 13px;
      height: 45px;
    }
  }
  .order {
    &__table {
      &-qty {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
      &-price {
        width: 50%;
        text-align: right;
      }
    }
  }
}

// 526
@media screen and (min-width: 526px) {
  .tabs {
    justify-content: center;
    flex-direction: row;
    margin-left: -20px;
    margin-right: -20px;
    &__btn {
      font-size: 16px;
      margin: 0 20px;
      width: 100%;
      &:before {
        width: 1px;
        height: 24px;
        top: 50%;
        right: -20px;
        bottom: unset;
        left: unset;
        margin-left: 0;
        margin-top: -12px;
      }
      &.is-active {
        text-decoration: underline;
      }
    }
  }
}

// 575
@media screen and (min-width: 575px) {
  .order {
    &__table {
      tr {
        display: table-row;
        position: static;
        padding: 0;
      }
      td {
        display: table-cell;
        position: relative;
        padding: 10px;
        width: auto;
        text-align: left;
      }
      &-photo {
        vertical-align: top;
        width: 120px !important;
      }
      &-title {
        padding-right: 40px;
      }
    }
    &__total {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      &-text {
        font-size: 20px;
        margin-bottom: 0;
      }
      &-description {
        font-size: 34px;
      }
    }
  }
  .cart {
    &__total {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
    &__action {
      flex-direction: row;
      justify-content: space-between;
      .btn {
        margin-top: 0;
        width: 300px;
      }
    }
  }
}

// 768
@media screen and (min-width: 768px) {
  .tabs {
    &__btn {
      width: auto;
    }
  }
  .measure {
    &__form {
      &-title {
        margin-bottom: 40px;
      }
      &-content {
        flex-direction: row;
        margin: 0 -30px;
      }
      &-item {
        width: 50%;
        padding: 0 30px;
      }
    }
  }
  .contacts {
    &__address {
      &-item {
        &:nth-child(2n) {
          .contacts {
            &__address {
              &-content {
                flex-direction: row-reverse;
              }
            }
          }
        }
      }
      &-content {
        display: flex;
      }
      &-map {
        width: 50%;
        margin-bottom: 0;
      }
      &-info {
        width: 50%;
      }
    }
  }
  .service {
    &__item {
      display: flex;
      text-align: left;
      &-left {
        margin-bottom: 0;
      }
      &-picture {
        width: 300px;
        min-width: 300px;
      }
      &-right {
        display: flex;
        flex-direction: column;
        padding-left: 25px;
          width: 100%;
      }
      &-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
      }
      &-price {
        margin-bottom: 0;
      }
    }
    &__form {
      &-title {
        margin-bottom: 40px;
      }
      &-content {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 -30px;
      }
      &-item {
        width: 50%;
        padding: 0 30px;
      }
    }
  }
    .cookies {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        &__text {
            margin-bottom: 0;
            padding-right: 25px;
        }
        .btn {
            width: 360px;
        }
    }
}

// 992
@media screen and (min-width: 992px) {
  section, .section {
    padding: 60px 0;
  }
  .section {
    &__title {
      font-size: 46px;
      line-height: 46px;
      margin-bottom: 60px;
    }
  }
  .page {
    &__title {
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 40px;
    }
  }
  .btn {
    font-size: 16px;
    height: 60px;
    padding: 0 50px;
    &__min {
      font-size: 13px;
      height: 45px;
    }
  }
  .tabs {
    justify-content: flex-start;
  }
  .scrollup {
    width: 50px;
    height: 50px;
    right: 50px;
    bottom: 50px;
    .icon {
      width: 20px;
      height: 20px;
    }
  }
  .callup {
    display: none;
  }
  .viberup {
      width: 50px;
      height: 50px;
      left: 50px;
      bottom: 50px;
      .icon {
          width: 50px;
          height: 50px;
      }
  }
  .features {
    padding: 100px 0;
    &__list {
      display: flex;
    }
    &__item {
      width: 33.3%;
      margin-bottom: 0;
      padding: 0 15px;
      &-picture {
        margin-bottom: 30px;
      }
      &-img {
        width: 100px;
        height: 100px;
      }
      &-title {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 15px;
      }
      &-description {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .post {
    &__top {
      margin-bottom: 40px;
    }
    &__picture {
      padding-bottom: 35%;
    }
  }
  .gallery {
    &__item {
      transition: all 200ms ease;
      &:hover {
        transform: scale(1.15);
        .gallery__item {
          &-description {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      &-description {
        opacity: 0;
        visibility: hidden;
        bottom: 50%;
        transform: translateY(50%);
      }
    }
  }
  .cart {
    &__list {
      max-height: 360px;
      overflow: auto;
    }
  }
}

// 1200
@media screen and (min-width: 1200px) {
  .order {
    &__cart {
      padding-left: 25%;
    }
  }
  .measure {
    &__content {
      background-position: bottom right -10%;
      &:before {
        display: none;
      }
    }
    &__form {
      &-title {
        text-align: left;
      }
      &-item {
        width: 33.3%;
      }
    }
  }
  .contacts {
    &__reply {
      &-content {
        display: flex;
        align-items: center;
      }
      &-picture {
        display: block;
        width: 450px;
        margin: 0 50px;
      }
    }
  }
}

// 1367
@media screen and (min-width: 1367px) {
  .measure {
    &__content {
      background-position: bottom right 10%;
    }
  }
  .contacts {
    &__reply {
      &-picture {
        margin: 0 150px;
      }
    }
  }
}

