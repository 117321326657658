.ui-slider {
  position: relative;
  text-align: left;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-slider .ui-slider-handle.ui-state-active {
  background-color: #333333;
  border: none;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

.ui-slider.ui-state-disabled .ui-slider-handle, .ui-slider.ui-state-disabled .ui-slider-range {
  -webkit-filter: inherit;
  filter: inherit;
}

.ui-slider-horizontal {
  height: 4px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -7px;
  margin-left: -10px;
  outline: none;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.ui-widget {
  font-family: Verdana,Arial,sans-serif;
  font-size: 1.1em;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Verdana,Arial,sans-serif;
  font-size: 1em;
}

.ui-widget-content {
  width: 100%;
  margin: 20px 0 15px;
  background: #e5e5e5;
  color: #292929;
  border: none!important;
}

.ui-widget-content a {
  color: #292929;
}

.ui-widget-header {
  background: #88bba4;
  color: #88bba4;
  font-weight: bold;
}

.ui-widget-header a {
  color: #88bba4;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  font-weight: normal;
  color: #127749;
  background: #127749;
  transition: none;
  border: none;
  box-shadow: 0 2px 5px rgba(27, 27, 27, 0.35);
}

.ui-state-default a {
  color: #2b333d;
  text-decoration: none;
}

.ui-state-default a:link, .ui-state-default a:visited {
  color: #2b333d;
  text-decoration: none;
}