.breadcrumbs {
  padding: 20px 0;
  border-top: 1px solid $gray-100;

  &__list {
    display: flex;
    //flex-wrap: wrap;
    overflow-x: auto;
  }
  &__item {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 15px;
    color: $gray;
    padding: 2px 0;
    position: relative;
    white-space: nowrap;
    &:after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0 5px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23383d40'%3E%3Cpath d='M6.3 0L4 2.4l7.4 7.6L4 17.6 6.2 20 16 10z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 6px;
    };
    &:last-child, &:only-child {
      &:after {
        display: none;
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 15px;
    color: $default;
    text-align: center;
    .icon {
      width: 13px;
      height: 13px;
      fill: $default;
    }
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}
